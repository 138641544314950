 
export default defineNuxtPlugin(async() => {
    try {
        const response = await fetch("https://shopify-gtm-suite.getelevar.com/configs/c0f964290fa91689d9553ce34c1aef1fbf2642de/config.json");
        const config = await response.json();
        const scriptUrl = config.script_src_custom_pages;
    
        if (scriptUrl) {
          const { handler } = await import(/* @vite-ignore */ scriptUrl);
          await handler(config);
        }
      } catch (error) {
        console.error("Elevar Error:", error);
      }
});