<template>
    <section class="h-screen bg-white">
        <div
            class="relative flex flex-col items-center justify-center h-screen max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6 ">
            <div class="max-w-screen-sm mx-auto text-center">
                <h1 class="mb-4 font-extrabold tracking-tight text-7xl lg:text-9xl text-lifeboost-red-500">{{
                    errorMutator().statusCode }}</h1>
                <p class="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">Oops!</p>
                <p class="mb-4 text-2xl font-normal text-gray-500">{{ errorMutator().message }}</p>
                <button class="btn-red" onclick="window.history.back();">Go Back</button>
            </div>
        </div>
    </section>
</template>
<script setup>
const error = useError();
const { message, statusCode } = error.value;
const errorMutator = () => {
    if (!isDev) {
        console.log(`${statusCode} error occurred: ${message}`);
        return {
            message: statusCode === 404 ? `It seems you've stumbled upon a page that doesn't exist. Don't worry, we've sent our best detectives to find it!` : 'An error occurred',
            statusCode
        }
    } else if (isDev) {
        return {
            message,
            statusCode
        }
    }
}
</script>