import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import type { Component, } from 'vue'
import { defineAsyncComponent } from 'vue'
//@ts-ignore
import type { ComponentInfo } from '@builder.io/sdk-vue'

type InternalBuilderComponent = {
  name: string
  data: ComponentInfo & { ignored?: boolean }
  component: () => Promise<{ default: Component }>
}

export default defineNuxtPlugin(async (nuxtApp) => {
  //@ts-ignore
  const _components = (await import('#build/builder/components.mjs')).default
  const { prefix } = useRuntimeConfig().public.builderIO.components
  const components = await Promise.all((_components as InternalBuilderComponent[])
    .filter(component => component.data && !component.data.ignored)
    .map(async (component) => {
      // console.log(component.component());
      // const vueComponent = 
      return {
        ...component.data,
        component: defineAsyncComponent(async() => (await component.component()).default),
        name: prefix + component.data.friendlyName,
        ignored: undefined
      }
    })
  )

  // Provide components to both client and server
  nuxtApp.provide('builderComponents', components)
})
