import revive_payload_client_vjGmhOQW6d from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BkRB04d1TL from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IAKNLkSf3R from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZmcLFvc6wK from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hAFDA8S28z from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Zv4MnV2zrx from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Xg0nrniNIB from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_NBArRVQCXq from "/vercel/path1/node_modules/.pnpm/nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node@14.18.33_ioredis@5.4.1_magicast@0.3.4_rollup@4._lttpmlzorgh6yp5bdjwirzntyi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_xWFqnUkxrm from "/vercel/path1/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import components_n2DBOvudZ6 from "/vercel/path1/modules/builder-components/runtime/plugins/components.ts";
import plugin_RoPwWRcHKE from "/vercel/path1/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.4_nuxt@3.12.3_@parcel+watcher@2.4.1_@types+node_67cxxwdrscia24ybukogndrpqq/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import elevar_client_gLRCdr3tMV from "/vercel/path1/plugins/elevar.client.js";
import error_hunter_TTTWkzE0Hy from "/vercel/path1/plugins/error-hunter.js";
import vercel_client_MPxe0eTJnr from "/vercel/path1/plugins/vercel.client.js";
export default [
  revive_payload_client_vjGmhOQW6d,
  unhead_BkRB04d1TL,
  router_IAKNLkSf3R,
  payload_client_ZmcLFvc6wK,
  navigation_repaint_client_hAFDA8S28z,
  check_outdated_build_client_Zv4MnV2zrx,
  chunk_reload_client_Xg0nrniNIB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NBArRVQCXq,
  plugin_xWFqnUkxrm,
  components_n2DBOvudZ6,
  plugin_RoPwWRcHKE,
  elevar_client_gLRCdr3tMV,
  error_hunter_TTTWkzE0Hy,
  vercel_client_MPxe0eTJnr
]