import { default as _91_46_46_46slug_93DYbjSFWTvJMeta } from "/vercel/path1/pages/[...slug].vue?macro=true";
import { default as cajamarca0RNyI5jeG4Meta } from "/vercel/path1/pages/cajamarca.vue?macro=true";
import { default as coffee_45and_45liverMpXFk8ywUsMeta } from "/vercel/path1/pages/coffee-and-liver.vue?macro=true";
import { default as drops3FEnOHpOnoMeta } from "/vercel/path1/pages/drops.vue?macro=true";
import { default as eventgglOjIat97Meta } from "/vercel/path1/pages/event.vue?macro=true";
import { default as fit_45foamDYHewUcBWWMeta } from "/vercel/path1/pages/fit-foam.vue?macro=true";
import { default as frosted_45cookiexlaJkRbJhwMeta } from "/vercel/path1/pages/frosted-cookie.vue?macro=true";
import { default as gesha2j8uwPMBvXMeta } from "/vercel/path1/pages/gesha.vue?macro=true";
import { default as healthy_45coffee_4525dlW3kra8FeMeta } from "/vercel/path1/pages/healthy-coffee-25.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as indonesiaTvGp6cWRDrMeta } from "/vercel/path1/pages/indonesia.vue?macro=true";
import { default as lifeboocharGlIKVaZFuMeta } from "/vercel/path1/pages/lifeboocha.vue?macro=true";
import { default as lifeboostlatte1GUsrCjgC4kMeta } from "/vercel/path1/pages/lifeboostlatte1.vue?macro=true";
import { default as mitocreamer2Xus6XA1MIMeta } from "/vercel/path1/pages/mitocreamer.vue?macro=true";
import { default as rainbowKTuZsYON4wMeta } from "/vercel/path1/pages/rainbow.vue?macro=true";
import { default as real_45mushroom_45coffee1sLdWkK0cLMeta } from "/vercel/path1/pages/real-mushroom-coffee.vue?macro=true";
import { default as signin59vjf3AtmFMeta } from "/vercel/path1/pages/signin.vue?macro=true";
import { default as sipsclubAIIEdahX7aMeta } from "/vercel/path1/pages/sipsclub.vue?macro=true";
import { default as snoozyWqfaJCKMZoMeta } from "/vercel/path1/pages/snoozy.vue?macro=true";
import { default as strawberry_45lifeboochaF4qG6ZmIbKMeta } from "/vercel/path1/pages/strawberry-lifeboocha.vue?macro=true";
import { default as swatchesZFaesxDUq1Meta } from "/vercel/path1/pages/swatches.vue?macro=true";
import { default as testZF6U7geGWeMeta } from "/vercel/path1/pages/test.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path1/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "cajamarca",
    path: "/cajamarca",
    component: () => import("/vercel/path1/pages/cajamarca.vue").then(m => m.default || m)
  },
  {
    name: "coffee-and-liver",
    path: "/coffee-and-liver",
    component: () => import("/vercel/path1/pages/coffee-and-liver.vue").then(m => m.default || m)
  },
  {
    name: "drops",
    path: "/drops",
    component: () => import("/vercel/path1/pages/drops.vue").then(m => m.default || m)
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/vercel/path1/pages/event.vue").then(m => m.default || m)
  },
  {
    name: "fit-foam",
    path: "/fit-foam",
    component: () => import("/vercel/path1/pages/fit-foam.vue").then(m => m.default || m)
  },
  {
    name: "frosted-cookie",
    path: "/frosted-cookie",
    component: () => import("/vercel/path1/pages/frosted-cookie.vue").then(m => m.default || m)
  },
  {
    name: "gesha",
    path: "/gesha",
    component: () => import("/vercel/path1/pages/gesha.vue").then(m => m.default || m)
  },
  {
    name: "healthy-coffee-25",
    path: "/healthy-coffee-25",
    component: () => import("/vercel/path1/pages/healthy-coffee-25.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "indonesia",
    path: "/indonesia",
    component: () => import("/vercel/path1/pages/indonesia.vue").then(m => m.default || m)
  },
  {
    name: "lifeboocha",
    path: "/lifeboocha",
    component: () => import("/vercel/path1/pages/lifeboocha.vue").then(m => m.default || m)
  },
  {
    name: "lifeboostlatte1",
    path: "/lifeboostlatte1",
    component: () => import("/vercel/path1/pages/lifeboostlatte1.vue").then(m => m.default || m)
  },
  {
    name: "mitocreamer",
    path: "/mitocreamer",
    component: () => import("/vercel/path1/pages/mitocreamer.vue").then(m => m.default || m)
  },
  {
    name: "rainbow",
    path: "/rainbow",
    component: () => import("/vercel/path1/pages/rainbow.vue").then(m => m.default || m)
  },
  {
    name: "real-mushroom-coffee",
    path: "/real-mushroom-coffee",
    component: () => import("/vercel/path1/pages/real-mushroom-coffee.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/vercel/path1/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "sipsclub",
    path: "/sipsclub",
    component: () => import("/vercel/path1/pages/sipsclub.vue").then(m => m.default || m)
  },
  {
    name: "snoozy",
    path: "/snoozy",
    component: () => import("/vercel/path1/pages/snoozy.vue").then(m => m.default || m)
  },
  {
    name: "strawberry-lifeboocha",
    path: "/strawberry-lifeboocha",
    component: () => import("/vercel/path1/pages/strawberry-lifeboocha.vue").then(m => m.default || m)
  },
  {
    name: "swatches",
    path: "/swatches",
    component: () => import("/vercel/path1/pages/swatches.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path1/pages/test.vue").then(m => m.default || m)
  }
]